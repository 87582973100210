html, body {
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	font-family: 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
}

* {
	box-sizing: border-box;
}

button {
    font-size: 20px;
    color: #444;
}
 select {
    font-size: 16px;
    color: #444;
    border: 1px solid #444;
    padding: 2px 4px;
 }

.big-input {
    width: 720px;
    font-size: 20px;
}

.md-wide {
    width: 300px;
}

.main-div {
    margin: 80px 10px 40px;
}

table {
    border-collapse: collapse;
}

label {
    margin-left: 6px;
}

.word-table td {
    border-bottom: 1px solid #ccc;
    padding: 8px 4px 14px;
}

.word-table td.speaker-cell,
.word-table td.repeat-cell {
    width: 40px;
}

.lo-lite {
    display: block;
    color: #999;
    font-size: x-small;
    font-style: italic;
}

.speaker-img,
.repeat-img {
    margin: 0px 10px -8px 20px;
}

.checked-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.to-lang-img {
	height: 14px;
	width: 14px;
    margin: 0 8px;
}

.settings-table {
    margin-top: 18px;
}

.settings-table td {
    padding: 8px 10px;
}

.settings-table td.table-subsection {
    padding-top: 28px;
    color: #999;
}

.settings-table td.table-subsection.first {
    padding-top: 48px;
    color: #999;
}

.validator .change-message {
    border: 1px solid #21468B;;
    color: #21468B;;
    margin-bottom: 20px;
    padding: 6px;
}

.validator .changed-text {
    color: purple;
}

@media screen and (max-width: 720px) {
    .word-table {
        width: 100%;
    }
}

/* @import "./splitter"; */
.center-content {
    text-align: center;
}

#overview-container {
    height: 90px;
}

/* @import "./recorder"; */
#audio-visuals {
    height: 200px;
}

#volume-container {
    height: 200px;
}

#volume {
    margin-top: 4px;
    height: 20px;
    width: 2%;
    background-color: mediumorchid;
    border: 1px solid darkmagenta;
}

.lang-input {
    margin-bottom: 20px;
}

.work-message {
    margin-top: 6px;
    color: mediumorchid;
}



.hidden { 
    display:none;
}

/* @import "./header"; */
header {
    display: flex;
    justify-content: space-between;
}

header .menu-img {
    height: 34px;
    margin: 10px;
    cursor: pointer;
}

.main-menu {
    position: fixed;
    top: 62px;
    right: 6px;
    background-color: #eee;
}

.main-menu > div {
    margin: 6px;
    border: 2px solid #ccc;
    padding: 4px 16px;
}

.clickable {
    cursor: pointer;
}

/* @import "./editor"; */
.editor-controls {
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    padding: 8px 18px;
    display: flex;
    justify-content: space-between;
    background-color: #eee;
    box-shadow: 0 0 5px rgb(0 0 0 / 50%);
    font-size: 12px;
}

.editor-controls button {
    font-size: 16px;
}

.left-editor-controls button {
    max-width: 116px
}

.editor-div .word-table {
    margin-top: 118px;
}

.selected-categories button {
    border: none;
    font-size: 12px;
    padding: 0;
    color: #21468B;
    background-color: transparent;
    margin-top: 4px;
}
